<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Departamentos </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click="atualizarDados">
                <v-icon dark>sync</v-icon> Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'product-dept.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Novo Departamento
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadProductDepts" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.prod_dp_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.prod_dp_status" @change="onChangeEventHandlerStatus(item.prod_dp_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>
            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editProductDept(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteProductDept(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>
    </v-card>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "ProductDeptComponent",
    created() {
        this.$store.dispatch('loadProductDepts');
    },
    computed: {
        loadProductDepts() {
            if (this.$store.state.productdepts.items.data == null)
                return []

            return this.$store.state.productdepts.items.data;
        }
    },
    data() {
        return {
            search: '',
            rowsPerPageItems: [10, 20, 30, 50, {
                "text": "$vuetify.dataIterator.rowsPerPageAll",
                "value": -1
            }],
            headers: [
                {
                    sortable: false,
                    text: 'Código',
                    value: 'prod_dp_codigo'
                },
                {
                    sortable: false,
                    text: 'Nome',
                    value: 'prod_dp_nome'
                },
                {
                    sortable: false,
                    text: 'Ordem',
                    value: 'prod_dp_ordem'
                },
                {
                    sortable: false,
                    text: 'Status',
                    value: 'prod_dp_status'
                },
                {
                    text: 'Ações',
                    align: 'center',
                    sortable: false,
                    value: 'acoes'
                }
            ],
            productdept: [],
            base_url: URL_BASE
        }
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch('loadProductDepts');
        },
        editProductDept(item) {
            this.$router.push({
                name: 'product-dept.edit',
                params: {
                    prod_dp_id: item.prod_dp_id
                }
            });
        },
        detailProductDept(item) {
            this.$router.push({
                name: 'product-dept.detail',
                params: {
                    prod_dp_id: item.prod_dp_id
                }
            });
        },
        deleteProductDept(item) {
            this.$swal({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter isso",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('destroyProductDept', item.prod_dp_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: 'Sucesso',
                                    text: 'Dados excluídos com sucesso',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.atualizarDados();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: 'Opssss!',
                                    text: 'Erro ao excluir dados',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: 'Opssss!',
                                text: 'Algo errado aconteceu!',
                                showConfirmButton: true,
                                timer: 6000
                            });
                        })
                }
            })
        },
        onChangeEventHandlerStatus(prod_dp_id, value) {
            this.$store.dispatch('updateProductDeptStatus', {
                    prod_dp_id: prod_dp_id,
                    prod_dp_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Status do Status atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar status do Status',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {

    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
